import Vue from 'vue'
import Vuex from 'vuex'

import modAlert from './modules/alert'
import modLoader from './modules/loader'
// import modMainmenu from './modules/mainmenu'
// import modVendas from './modules/vendas'
// import modDadosAuth from './modules/dadosAuth'
// import transferArea from './modules/transferArea'

/**
 * Exemplo de como importar um arquivo para uso geral que
 * pode ser usado como getters e setters(mutations)
 */
import * as getters from './gettersHelp'
//ou
//import { getDadosLogin,getPI} from './getters'

Vue.use(Vuex)

const createStore =  new Vuex.Store({
    getters,
    modules:{
        modAlert,
        modLoader,
        // modMainmenu,
        // modVendas,
        // modDadosAuth,
        // transferArea,
    }
})

export default createStore
