<template>
    <div id="chat-container">
      <v-icon id="icon-close-chat" @click="deleteChatBot()" color="red">mdi-close-circle</v-icon>
    </div>
  </template>

  <script>

  export default {
    name: "Chatbot",
    created() {
      var host = window.location.host;
      var subdomain = host.split('.')[0];

      if (['acd', 'portal', 'assinar'].includes(subdomain)) {
        this.chatBot();
      }
    },
    methods: {
      async chatBot() {
        if (document.getElementById('chatbot')) {
          return;
        }
        let js = document.createElement("script");
        js.id = "chatbot";
        js.type = "text/javascript";
        js.src = "https://go.botmaker.com/rest/webchat/p/J406HBIZMD/init.js";

        document.body.appendChild(js);
        setTimeout(function () {
          document.querySelector('iframe[title=Botmaker]').parentElement.id = "chatbot";
          document.querySelector('iframe[title=Botmaker]').style.setProperty('height', '30px', '!important');
          document.querySelector('iframe[title=Botmaker]').contentWindow.document.querySelector('.wc-button-2').style.setProperty('display', 'none', '!important');
          document.querySelector('iframe[title=Botmaker]').contentDocument.querySelector('body .wc-new-messages-counter').remove();
          setTimeout(function () {
            document.getElementById("icon-close-chat").style.display = 'block';
          }, 800);
        }, 2500);

      },
      async deleteChatBot() {
        document.querySelector('iframe[title=Botmaker]').style.visibility = 'hidden';
        document.querySelector('iframe[title=Botmaker]').style.opacity = '0';
        document.querySelector('iframe[title=Botmaker]').style.transition = 'visibility 0s linear 300ms, opacity 300ms';

        document.getElementById("icon-close-chat").style.visibility = 'hidden';
        document.getElementById("icon-close-chat").style.opacity = '0';
        document.getElementById("icon-close-chat").style.transition = 'visibility 0s linear 300ms, opacity 300ms';

        setTimeout(function () {
          document.querySelector('iframe[title=Botmaker]').remove();
          document.getElementById("icon-close-chat").remove();
          document.getElementById("chatbot").remove();
        }, 1500);
      }
    }
  }
  </script>

  <style>
  #chatbot {
    bottom: -7px !important;
    z-index: 3 !important;
  }

  #chat-container {
    position: fixed;
    width: 20px;
    height: 20px;
    bottom: 3px;
    right: 30px;
    margin-bottom: 45px;
    z-index: 10;
  }

  #icon-close-chat {
    position: absolute;
    right: -2px;
    top: -4px;
    display: none;
    cursor: pointer;
  }
  </style>
