export default {
    state:{
        alertBar:{
            status:false,
            text:'',
        }
    },
    getters:{
        getAlertBar(state){
            return state.alertBar.status
        }
    },
    mutations:{
        alert(state,value){
            state.alertBar.status = value.status
            state.alertBar.text   = value.text
        },
        setAlertBar(state,alertText){
            state.alertBar.text = alertText
            if(state.alertBar.status){
                state.alertBar.status = false
            }else{
                state.alertBar.status = true
            }
        }

    }
}
