<template>
    <div>
      <v-row>
        <v-col cols="12" class="titulo-pagamento">
          <p>Documentos</p>
        </v-col>
      </v-row>

      <v-row class="my-10 mx-auto flex ga-10" align-content="center" justify="center"
      :style="$vuetify.breakpoint.mdAndUp ? 'width: 60%' : 'width: 90%'">
          <div class="input-documento d-flex flex-column justify-center align-center ma-auto">
            <v-file-input
                prepend-icon="mdi-cloud-upload"
                hide-input
                accept="image/*, application/pdf, application/zip, application/rar"
                v-model="file"
            ></v-file-input>
            <p v-if="fileType === null" style="white-space: pre-line; color: #52525b; font-size: 0.9rem; margin-top: 10px; text-align: center;">
              Insira uma imagem ou PDF. Caso precise enviar vários arquivos, envie no formato .zip
              Tamanho máximo do arquivo não pode ser maior que 20Mb
            </p>
          </div>
          <div v-if="fileType" class="d-flex justify-center mx-auto">
            <div v-if="fileType === 'pdf'">
              <object class="pdf" width="500" height="300" :data="previewImage()">
              </object>
            </div>
            <div v-else style="border: 2px gray">
              <img v-if="fileType === 'image'" width="500" height="300" style="object-fit: scale-down;" :src="previewImage()" alt="Preview imagem carregada"/>
              <div v-else v-html="previewImage()"></div>
              <div>
                <p style="white-space: pre-line">
                  Nome do arquivo: {{this.file?.name}}
                  Tamanho: {{this.file?.size / 1000}}  kB
                </p>
              </div>
            </div>
          </div>
      </v-row>

      <v-row class="my-3 flex justify-center">
        <div v-if="documentoCpf">
          <p><strong>Documento de Identificação CNH</strong></p>
          <ul>
            <li>Caso outro documento de identificação com foto seja enviado, solicitamos que aguarde junto ao Atendente a
              confirmação da possibilidade de atendimento.
            </li>
          </ul>
        </div>

        <div v-if="documentoCnpj">
          <p><strong>Documentos necessários para CNPJ</strong></p>
          <ul>
            <li>Caso seja MEI: Anexar documento de identificação + consulta CEI,</li>
            <li>Caso possua QSA: Anexar documento de identificação + consulta QSA,</li>
            <li>Caso não seja mais MEI: Anexar documento de identificação + Alteração Contratual,</li>
            <li>Caso seja do estado de SP: Anexar documento de identificação + Certidão Simplificada.,</li>
            <li>Documento de Identificação: CNH (Preferencialmente),</li>
            <li>Caso outro documento de identificação com foto seja enviado, solicitamos que aguarde junto ao Atendente a
              confirmação da possibilidade de atendimento.
            </li>
          </ul>
        </div>
      </v-row>

      <hr>

      <v-row>
        <v-col cols="2" class="d-flex justify-start">
          <v-btn color="blue" dark @click="voltarPasso">
            Voltar
          </v-btn>
        </v-col>

        <v-col cols="2" offset="8" class="d-flex justify-end">
          <v-btn color="green" dark @click="enviarArquivo">
            Próximo
          </v-btn>
        </v-col>
      </v-row>

    </div>
  </template>


  <script>

  import {servico} from "@/services/VendasServices";
//   import Echo from "laravel-echo";

  export default {
    data() {
      return {
        file: null,
      }
    },
    name: 'Documentos',
    components: "",
    props: {
      venda: [Object, Array],
      documentoCpf: Boolean,
      documentoCnpj: Boolean,
    },
    created() {
    //   this.connectWS();
    },
    computed: {
      fileType() {
        const type = (this.file && this.file.type) || '';

        if (type.includes('image')) return 'image'
        if (type === 'application/zip' || type === 'application/rar') return 'zip'
        if (type === 'application/pdf') return 'pdf'

        return null
      },
    },
    methods: {
      proximoPasso() {
        this.$emit("proximoPasso");
      },
      voltarPasso() {
        this.$emit("voltarPasso");
      },
      notificarFaltaDeDocumento() {
        this.$emit("notificarDocumento");
      },
      enviarArquivo() {
        if (!this.file) {
          this.notificarFaltaDeDocumento();
          return
        }

        servico.uploadCnh({venda_id: this.venda.id, file: this.file})
            .then(() => {
              this.$notify({
                type: "success",
                title: "Sucesso",
                text: "Tudo certo, arquivos atualizados ."
              });
              this.proximoPasso()
            })
            .catch((err) => {
              if (err.response.status === 422) {
                this.$notify({type: "error", title: "Erro:", text: err.response.data.message,});
              } else {
                this.$notify({type: "error", title: "Erro:", text: err,});
              }
            })
            .finally(() => this.btnFimLoading = false)
      },

      previewImage() {
        if (!this.file) {
          return null
        }

        if (this.fileType === 'zip') {
          return '<svg xmlns="http://www.w3.org/2000/svg" style="fill: #52525b; width: 50px" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM96 48c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm-6.3 71.8c3.7-14 16.4-23.8 30.9-23.8h14.8c14.5 0 27.2 9.7 30.9 23.8l23.5 88.2c1.4 5.4 2.1 10.9 2.1 16.4c0 35.2-28.8 63.7-64 63.7s-64-28.5-64-63.7c0-5.5 .7-11.1 2.1-16.4l23.5-88.2zM112 336c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z"/></svg>';
        }

        if (this.fileType === 'image' || this.fileType === 'pdf') {
          return URL.createObjectURL(this.file)
        }

        return null;
    //   },

    //   connectWS() {
    //     window.Pusher = require('pusher-js');
    //     if (!this.venda || this.venda.status_venda_id === 2 || window.wsConected) {
    //       return;
    //     }

    //     window.Echo = new Echo({
    //       broadcaster: 'pusher',
    //       key: process.env.VUE_APP_WS_KEY,
    //       wsHost: process.env.VUE_APP_WS_URL,
    //       wsPort: process.env.VUE_APP_WS_PORT,
    //       cluster: "mt1",
    //       forceTLS: false,
    //       disableStats: true,
    //     });

    //     window.Echo.channel(`consulta-pagamento-venda.${this.venda.id}`)
    //         .listen('EventStatusPagamentoVenda', (e) => {
    //           if(e.venda_status_id === 2) {
    //             this.$emit("pagamentoEfetuado", e.venda_status_id);
    //           }
    //         });
      }
    },
    watch: {
      loader() {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
  }
  </script>

  <style>
  .titulo-pagamento {
    font-size: 1.6em;
    border-radius: 7px;
    font-weight: bold;
  }

  hr {
    border: 0.5px solid;
    border-color: #dedede;
  }

  .input-documento .v-icon {
    font-size: 80px;

  }

  .v-input {
    gap: 12px;
  }


  </style>
