<template>
    <v-overlay :value="overlay" class="loader" ref="loader">
      <input ref="loader" type="hidden">
      <v-progress-circular
          indeterminate
          size="100"
          :color="color"
          absolute="absolute"
      ></v-progress-circular>
    </v-overlay>
  </template>

  <script>
  export default {
    name: 'Loader',
    props: {
      color: String
    },
    computed: {
      overlay() {
        return this.$store.getters.getStatus;
      }
    }
  }
  </script>

  <style>
  .loader {
    z-index: 9999999 !important
  }
  </style>
