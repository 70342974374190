<template>
    <div style="height: 100%">
      <v-container style="height: 100%">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-img :src="logo" ></v-img>
          </v-col>
        </v-row>
      </v-container>

    </div>

  </template>
  <script>
  export default {
    name: "Home",
    data: () => ({
      logo: "/img/logotipo.png"
    }),
    created() {
      window.location.href = 'https://certificadoexpress.com.br';
    }
  }
  </script>
