import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'
import Notifications from 'vue-notification'
import NativeNotification from './plugins/native-notification'

Vue.config.productionTip = false

import Loader from './components/Loader.vue'
import Alertbar from './components/Alertbar.vue'
import Filters from './helpers/filters.js'

Vue.component('Loader', Loader)
Vue.component('Alertbar', Alertbar)

Vue.use(NativeNotification)
Vue.use(Notifications)
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Sim',
  buttonFalseText: 'Não',
  color: 'orange',
  icon: 'fas fa-exclamation-triangle',
  title: 'Atenção!',
  property: '$confirm',
  persistent: true
})

new Vue({
  router,
  store,
  Loader,
  Alertbar,
  vuetify,
  Filters,
  render: h => h(App)
}).$mount('#app')
