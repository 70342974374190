<template>
    <div>
      <CartaoCredito :venda_id="venda_id"></CartaoCredito>
    </div>
  </template>


  <script>

  import CartaoCredito from "@/components/CartaoCredito.vue";

  export default {
    name: 'FormularioPix',
    components: {CartaoCredito},
    props: {
      venda_id: Number
    }
  }
  </script>

  <style>

  </style>
