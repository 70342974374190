<template>
    <div>
      <v-slide-x-transition :hide-on-leave="true">
        <div v-show="step === current_step && transition">
          <slot></slot>
        </div>
      </v-slide-x-transition>

      <v-slide-x-reverse-transition :hide-on-leave="true">
        <div v-show="step === current_step && !transition">
          <slot></slot>
        </div>
      </v-slide-x-reverse-transition>
    </div>
  </template>
  <script>
  export default {
    name: "StepperBodyItem",
    props: {
      step: Number,
      current_step: Number,
    },
    data: () => ({
      transition: true,
      transition_type: null,
    }),
    watch: {
      current_step() {
        this.transition = true;
      }
    }
  }
  </script>
  <style scoped>
  </style>
