<template>
    <svg
        :width="getSize+'px'"
        :height="getSize+'px'"
        viewBox="0 0 133 133"
        fill="#4caf50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="check-group"
         stroke="none"
         stroke-width="1"
         fill="none"
         fill-rule="evenodd"
      >
  <!--      <circle-->
  <!--          id="filled-circle"-->
  <!--          fill="#4caf50"-->
  <!--          cx="66.5"-->
  <!--          cy="66.5"-->
  <!--          r="54.5"-->
  <!--      />-->
        <circle
            id="white-circle"
            fill="#FFFFFF"
            cx="66.5"
            cy="66.5"
            r="55.5"
        />
        <circle
            id="outline"
            stroke="#4caf50"
            stroke-width="4"
            cx="66.5"
            cy="66.5"
            r="54.5"
        />
        <polyline
            id="check"
            stroke="#4caf50"
            stroke-width="5.5"
            points="41 70 56 85 92 49"
        />
      </g>
    </svg>
  </template>

  <script>
  export default {
    name: "IconAnimadoCheck",
    props: {
      size: Number
    },
    computed: {
      getSize() {
        if (!this.size) return 95;
        return this.size
      }
    }

  }
  </script>

  <style scoped>
  #check-group {
    -webkit-animation: 0.32s ease-in-out 1.03s check-group;
    animation: 0.32s ease-in-out 1.03s check-group;
    transform-origin: center;
  }

  #check-group #check {
    -webkit-animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
    animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.5s forwards check;
    stroke-dasharray: 0, 75px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  #check-group #outline {
    -webkit-animation: 0.38s ease-in outline;
    animation: 0.38s ease-in outline;
    transform: rotate(0deg);
    transform-origin: center;
  }

  #check-group #white-circle {
    -webkit-animation: 0.35s ease-in 0.35s forwards circle;
    animation: 0.35s ease-in 0.35s forwards circle;
    transform: none;
    transform-origin: center;
  }

  @-webkit-keyframes outline {
    from {
      stroke-dasharray: 0, 345.576px;
    }

    to {
      stroke-dasharray: 345.576px, 345.576px;
    }
  }

  @keyframes outline {
    from {
      stroke-dasharray: 0, 345.576px;
    }

    to {
      stroke-dasharray: 345.576px, 345.576px;
    }
  }

  @-webkit-keyframes circle {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0);
    }
  }

  @keyframes circle {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0);
    }
  }

  @-webkit-keyframes check {
    from {
      stroke-dasharray: 0, 175px;
    }

    to {
      stroke-dasharray: 75px, 75px;
    }
  }

  @keyframes check {
    from {
      stroke-dasharray: 0, 175px;
    }

    to {
      stroke-dasharray: 75px, 75px;
    }
  }

  @-webkit-keyframes check-group {
    from {
      transform: scale(1);
    }

    50% {
      transform: scale(1.09);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes check-group {
    from {
      transform: scale(1);
    }

    50% {
      transform: scale(1.09);
    }

    to {
      transform: scale(1);
    }
  }


  </style>
