<template>
    <div>
      <img :src="imageSrc" alt="Imagem Aleatória" style="width: 100%; height: auto;">
    </div>
  </template>

  <script>
  export default {
    name: "ImagemAleatorio",
    data() {
      return {
        imageSrc: ''
      };
    },
    created() {
      // Gera a imagem com base no timestamp atual
      this.generateImage();
    },
    methods: {
      generateImage() {
        // Gera a chave com base no timestamp atual (minuto e segundo)
        const timestamp = Math.floor(Date.now() / 1000); // Divide por 1000 para obter segundos
        const key = timestamp % 3600; // 3600 segundos em uma hora

        // Usa a chave para gerar uma cor específica
        const color = this.generateColorFromKey(key);

        // Gera a imagem com o gradiente de cores
        this.imageSrc = this.generateGradientImage(color);
      },
      generateColorFromKey(key) {
        // Gera uma cor RGB com base na chave
        const red = Math.sin(key) * 127 + 128;
        const green = Math.sin(key + (2 * Math.PI) / 3) * 127 + 128;
        const blue = Math.sin(key + (4 * Math.PI) / 3) * 127 + 128;

        return `rgb(${red.toFixed(0)}, ${green.toFixed(0)}, ${blue.toFixed(0)})`;
      },
      generateGradientImage(color) {
        // Lógica para criar uma imagem com gradiente entre cores
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 500; // Largura da imagem
        canvas.height = 500; // Altura da imagem

        // Cria um gradiente linear entre cores
        const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
        gradient.addColorStop(0, 'red'); // Cor vermelha no início
        gradient.addColorStop(0.5, color); // Cor específica no meio
        gradient.addColorStop(1, 'blue'); // Cor azul no final

        // Preenche o gradiente no canvas
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Retorna a URL da imagem gerada
        return canvas.toDataURL();
      }
    }
  };
  </script>
