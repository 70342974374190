<template>
    <div>
      <slot></slot>
    </div>
  </template>
  <script>
  export default {
    name: "StepperBody",
  }
  </script>
  <style scoped>
  </style>
