import { http, multipart } from './config';

export const servico = {
    buscaTabela: (values) => {
        return http.post('/vendas/tabela', values)
    },
    buscaTabByPessoaId: (id) => {
        let uri = `/tabelas/pessoa/${id}`
        return http.get(uri)
    },
    comissionados: () => {
        return http.post('/vendas/comissionados')
    },
    finalizaVenda: (values) => {
        return http.post('/vendas', values)
    },
    uploadCnh: (values) => {
        return multipart.post('/vendas/upload-cnh', values)
    },
    listaVendas: (values) => {
        return http.post('/vendas/gridvendas', values)
    },
    dadosgrafico: () => {
        return http.get('/vendas/dadosgrafico')
    },
    buscaCadastro: (doc) => {
        let uri = `/vendas/doc/${doc}`
        return http.get(uri)
    },

    /** funções da grid de vendas */
    regBoleto: (id) => {
        let uri = `boleto/registro/${id}`
        return http.get(uri)
    },
    baixaPgto: (dados) => {
        return http.post('/vendas/baixapgto', dados)
    },

    getLink: (id) => {
        return http.get('/vendas/link/' + id)
    },

    sendLink: (values) => {
        return http.post('/vendas/sendlink', values)
    },

    getDetalhe: (id) => {
        return http.get(`/vendas/detalhes/${id}`);
    },

    getDadosVenda: (id) => {
        return http.get(`/vendas/dados/${id}`);
    },

    updateDadosCertificado(id, dados) {
        return http.put(`/vendas/dados-certificado/${id}`, dados)
    },

    cancelarVenda(id, dados) {
        return http.post(`/vendas/cancelar/${id}`, dados)
    },

    getVendasParceiro(dados) {
        return http.post(`/vendas/relatorio-parceiro`, dados);
    },

    getConstulaPedido(dados) {
        return http.post(`/vendas/relatorio-consulta-pedido`, dados);
    }
}
