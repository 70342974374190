<template>
    <v-row justify="center">
      <v-dialog v-model="snackbar" persistent max-width="400">
        <v-card>
          <v-card-title class="text--white" style="background: orange">
            <span style="color: #FFF !important;">Atenção</span>
            <v-spacer></v-spacer>
            <v-icon color="white">fas fa-exclamation-triangle</v-icon>
          </v-card-title>

          <v-card-text>
            <p>
              <br/>
              <strong> {{ text }}</strong>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn ref="btnAlert" text @click="altStatus()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

  <script>
  export default {
    name: "Alertbar",
    computed: {
      text() {
        return this.$store.state.modAlert.alertBar.text;
      },
      snackbar: {
        get: function () {
          return this.$store.getters.getAlertBar;
        },
        set: function (setValue) {
          this.$store.commit("setAlertBar", setValue);
        },
      },
    },
    methods: {
      altStatus() {
        this.$store.commit("setAlertBar", false);
      }
    },
  };
  </script>

  <style lang="css">
  .title {
    color: white;
  }
  </style>
