<template>
    <div>
      <div v-if="current_step > step">
        <svg width="44" height="37" viewBox="0 0 64 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.2043 56.4056C38.6151 56.4056 49.4867 45.534 49.4867 32.1232C49.4867 18.7124 38.6151 7.84082 25.2043 7.84082C11.7935 7.84082 0.921875 18.7124 0.921875 32.1232C0.921875 45.534 11.7935 56.4056 25.2043 56.4056Z" fill="#DEDEDE"/>
          <path d="M25.4793 50.8918L25.1713 50.367C20.4667 42.3513 7.96483 25.3407 7.83855 25.1698L7.6582 24.9248L11.9175 20.7154L25.4002 30.13C33.8893 19.1142 41.809 11.548 46.975 7.12969C52.6262 2.29646 56.3047 0.0713948 56.3418 0.0500728L56.4255 0H63.6505L62.9603 0.614626C45.2109 16.424 25.9723 50.0254 25.7807 50.363L25.4793 50.8918Z" fill="#14D200"/>
        </svg>

      </div>

      <div v-else-if="current_step  === step">
        <svg width="44" height="37" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: translateY(3px)">
          <path d="M45.7601 25.1232C45.7601 36.8771 36.2316 46.4056 24.4777 46.4056C12.7238 46.4056 3.19531 36.8771 3.19531 25.1232C3.19531 13.3693 12.7238 3.84082 24.4777 3.84082C36.2316 3.84082 45.7601 13.3693 45.7601 25.1232Z" fill="#DEF5FE" stroke="#00B3F5" stroke-width="6"/>
        </svg>
      </div>

      <div v-else>
        <svg width="44" height="37" viewBox="0 0 64 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.7531 56.4056C38.1639 56.4056 49.0355 45.534 49.0355 32.1232C49.0355 18.7124 38.1639 7.84082 24.7531 7.84082C11.3423 7.84082 0.470703 18.7124 0.470703 32.1232C0.470703 45.534 11.3423 56.4056 24.7531 56.4056Z" fill="#DEDEDE"/>
        </svg>
      </div>

      <span :style="'color:'+ getColor()">{{title}}</span>


    </div>

  </template>

  <script>
  export default {
    name: "StepperHeaderItem",
    props: {
      step: Number,
      title: String,
      current_step: Number,
    },
    methods: {
      getColor() {
        if(this.current_step > this.step) return '#14D200';
        return '#9C9C9C';
      }
    }
  }

  </script>


  <style scoped>
  </style>
