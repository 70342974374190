/**
 * Exemplo de como usar helpers para finalizades gerais no vuex,
 * veja a importação desse arquivo no store.js
 */
export const getDadosLogin = ()=>{
    // return $store.state.modDadosAuth.AuthData
}

export const getPI = ()=>{
    return 3.14
}
