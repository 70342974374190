import { render, staticRenderFns } from "./FormularioAgendamento.vue?vue&type=template&id=234c4eeb&scoped=true"
import script from "./FormularioAgendamento.vue?vue&type=script&lang=js"
export * from "./FormularioAgendamento.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234c4eeb",
  null
  
)

export default component.exports