<template>
  <v-app id="inspire" :style="{ background: setBackground }" class="personalizacao-sistema">

    <v-main class="ma-2" v-if="$route.meta.paginaExterna">
      <v-scale-transition mode="out-in">
        <router-view @setBackgroundColor="background_color_externa = $event"/>
      </v-scale-transition>
    </v-main>

    <Chatbot/>
    <Alertbar/>
    <Loader color="lime darken-2"/>
    <notifications
        class="notification-content"
        type="warn"
        position="right bottom"
        :duration="5000"
        closeOnClick
    ></notifications>
  </v-app>
</template>

<script>
import messages from "@/mixins/mixMessages";
import Chatbot from "@/components/Chatbot";

export default {
  name: "App",
  mixins: [messages],
  components: {
    Chatbot,
  },
  data() {
    return {
      empresa: null,
      background_color_externa: null,
      empresa_id: null,
      username_logado: null,
      dialog_notificacao: false,
      qtd_msg_nao_lidas: 0,
    }
  },
  async created() {
    await this.loaderStart()
  },
  watch: {
    async $route() {
      await this.loaderStart()
    }
  },
  computed: {
    setBackground() {
      if (this.$route.meta.paginaExterna) return this.background_color_externa;
      if (this.$route.name === "Login") return 'FFF';
      return '#f3f3f3';
    },
  },
  methods: {
    async loaderStart() {
      this.$store.commit("setStatus", true)
      setTimeout(() => {
        this.$store.commit("setStatus", false)
        let token = localStorage.getItem("customSession");
        if (token != null) {
          let session = JSON.parse(atob(token));
          this.empresa = session.empresa_fantasia;
          this.username_logado = session.username_logado;
        } else {
          this.empresa = null;
          this.empresa_id = null;
        }

      }, 1000)
    },


  },
};
</script>

<style lang="scss">
a:link {
  text-decoration: none;
}

.notification-content {
  font-size: 1.1em;
}

.vue-notification {
  border-radius: 3px;
}

.vue-notification-wrapper {
  width: 100% !important;
}

.personalizacao-sistema {
  --cor-sistema: #8BC34A;
  --cor-sistema-fundo: rgba(1, 174, 66, 0.13);
  --cor-sistema-btn: #8BC34A;
  --cor-sistema-btn1: #6a963a;
  --cor-sistema-btn2: #496b23;

  .cor-sistema {
    background-color: var(--cor-sistema) !important;
    border-color: var(--cor-sistema) !important;
  }

  .cor-sistema-fundo--text {
    background-color: var(--cor-sistema-fundo) !important;
    border-color: var(--cor-sistema) !important;
    color: #2ba415;
  }

  .cor-sistema--text {
    color: var(--cor-sistema) !important;
    caret-color: var(--cor-sistema) !important;
  }

  .cor-sistema--text1 {
    color: var(--cor-sistema-btn1) !important;
    caret-color: var(--cor-sistema) !important;
  }

  .cor-sistema--text2 {
    color: var(--cor-sistema-btn2) !important;
    caret-color: var(--cor-sistema) !important;
  }

  .cor-sistema--btn, .cor-sistema--title {
    background-color: var(--cor-sistema) !important;
    border-color: var(--cor-sistema) !important;
    color: #FFFFFF;
  }

  .cor-sistema--btn1 {
    background-color: var(--cor-sistema-btn1) !important;
    border-color: var(--cor-sistema) !important;
    color: #FFFFFF;
  }

  .cor-sistema--btn2 {
    background-color: var(--cor-sistema-btn2) !important;
    border-color: var(--cor-sistema) !important;
    color: #FFFFFF;
  }

  .background-card {
    //background-image: url("assets/imgs/background-circle.png");
    background-size: 90% auto;
    //background-size: cover;
    background-position: -450% 50%;
  }
}

.corpo-sistema {
  background: #FFF !important;
  height: 100%;
  border-radius: 15px;
  padding: 5px;
}

</style>
