import Vue from 'vue';
import Router from 'vue-router';
import PaginasExternas from "@/views/PaginasExternas/routes"
import Home from "@/views/PaginasExternas/views/Home/Home.vue";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...PaginasExternas,
        {
            meta: {
                verificaLogado: false,
                paginaExterna: true,
            },
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            meta: {
                verificaLogado: false,
                paginaExterna: true
            },
            path: '*',
            redirect: '/'
        }
    ]
});

export default router;
