import VendaRapida from "@/views/PaginasExternas/views/VendaRapida/VendaRapida";
import PaginaNaoEncontrada from "@/views/PaginasExternas/views/PaginaNaoEncontrada/PaginaNaoEncontrada";
import LinkExpirado from "@/views/PaginasExternas/views/LinkExpirado/LinkExpirado.vue";


export default [
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/pagina-nao-encontrada',
        name: 'PaginaNaoEncontrada',
        component: PaginaNaoEncontrada
    },
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/link-expirado',
        name: 'LinkExpirado',
        component: LinkExpirado
    },
    {
        meta: {
            verificaLogado: false,
            paginaExterna: true,
        },
        path: '/web/venda-rapida/:token',
        name: 'WebVendaRapida',
        component: VendaRapida
    }
]
