<template>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="12" xl="10">

          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-img :src="dados.personalizacao.link_logo"
                     max-width="100%" max-height="150" contain class="my-2"></v-img>
            </v-col>
          </v-row>

          <v-row v-if="dados.venda_rapida.titulo" justify="center">
            <v-col cols="12" class="d-flex align-center justify-center"
                   style="background: rgba(0,190,56,0.34); border-radius: 7px">
              <span style="font-size: 1.7em; font-weight: 500">{{ dados.venda_rapida.titulo }}</span>
            </v-col>
          </v-row>

          <v-row id="corpo-pagina">
            <v-col cols="12">
              <Stepper>
                <StepperHeader :itens="itens" :current_step="step"></StepperHeader>

                <StepperBody>
                  <StepperBodyItem :step="1" :current_step="step">
                    <FormularioCompra
                        :token="dados.venda_rapida.token"
                        :venda_id="dados.venda_rapida.venda_id"
                        :tabela_preco_id="dados.venda_rapida.prod_tabelas_id"
                        :produtos="dados.produtos"
                        :solicitacao_soluti="dados.pedido_soluti?.solicitacao"
                        :dados_certificado="dados.dados_certificado"
                        @setNovaVenda="dados.venda_rapida.venda_id = $event"
                        @proximoPasso="step = 2"
                    ></FormularioCompra>
                  </StepperBodyItem>

                  <StepperBodyItem :step="2" :current_step="step">
                    <Pagamento
                        class="px-15"
                        :pagamento_obrigatorio="dados.venda_rapida.pagamento_obrigatorio"
                        :venda="dados.venda"
                        :venda_item="dados.venda_item"
                        :dados_certificado="dados.dados_certificado"
                        @pagamentoEfetuado="dados.venda.venda_status_id = $event"
                        @proximoPasso="step = 3"
                        @voltarPasso="step = 1"
                    ></Pagamento>
                  </StepperBodyItem>

                  <StepperBodyItem :step="3" :current_step="step">
                    <Documentos
                        :venda="dados.venda"
                        @proximoPasso="step = 4"
                        @voltarPasso="step = 2"
                        @notificarDocumento="dialog = true"
                        :documentoCnpj="documentoCnpj"
                        :documentoCpf="documentoCpf"
                    ></Documentos>
                  </StepperBodyItem>

                  <StepperBodyItem :step="4" :current_step="step">
                    <Agendamento
                        class="px-15"
                        :venda_id="dados.venda_rapida.venda_id"
                        :data_hora_agendada="dados.agendamento?.data_hora_agendada"
                        @agendamentoEfetuado="dados.agendamento.data_hora_agendada = $event"
                        @proximoPasso=" step = 5; loadPagina()"
                        @voltarPasso="step = 3"
                    ></Agendamento>
                  </StepperBodyItem>

                  <StepperBodyItem :step="5" :current_step="step">
                    <AgendamentoConfirmado
                        :venda_id="dados.venda_rapida.venda_id"
                        :cliente="dados.dados_certificado?.nome_razao"
                        :cpf_cnpj="dados.dados_certificado?.cpf_cnpj"
                        :produto="dados.venda_item?.nome_produto"
                        :data_hora_agendada="dados.agendamento?.data_hora_agendada"
                        :link_sala="dados.agendamento?.link_cliente"
                        :url_logo="dados.personalizacao?.link_logo"
                        @reagendar="step = 4"
                    ></AgendamentoConfirmado>
                  </StepperBodyItem>
                </StepperBody>
              </Stepper>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <v-col>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="290"
            >
              <v-card>
                <v-card-title class="text-h5">
                  ATENÇÃO
                </v-card-title>
                <v-card-text>Você ainda não anexou o documento, por favor anexe e tente novamente.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="green darken-1"
                      text
                      @click="dialog = false"
                  >
                    OK
                  </v-btn>
                  <!-- <v-btn
                      color="red darken-1"
                      text
                      @click="() => {
                        step++
                        dialog = false
                      }"
                  >
                    Sim
                  </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-col>

      </v-row>
    </v-container>
  </template>

  <script>
  import messages from "@/mixins/mixMessages";
  import Stepper from "@/components/Stepper/Stepper";
  import StepperHeader from "@/components/Stepper/StepperHeader";
  import StepperBody from "@/components/Stepper/StepperBody";
  import StepperBodyItem from "@/components/Stepper/StepperBodyItem";
  import FormularioCompra from "@/views/PaginasExternas/views/VendaRapida/components/FormularioCompra";
  import Pagamento from "@/views/PaginasExternas/views/Pagamento/Pagamento.vue";
  import Agendamento from "@/views/PaginasExternas/views/AgendamentoVideoconferencia/FormularioAgendamento";
  import AgendamentoConfirmado from "@/views/PaginasExternas/views/AgendamentoVideoconferencia/AgendamentoConfirmado";
  import {services} from "@/services/PaginasExternasServices";
  import Documentos from "@/views/PaginasExternas/views/Documentos/Documentos.vue";

  export default {
    name: 'WebVendaRapida',
    mixins: [messages],
    components: {
      Documentos,
      Agendamento,
      Pagamento,
      FormularioCompra,
      StepperHeader,
      StepperBody,
      StepperBodyItem,
      Stepper,
      AgendamentoConfirmado
    },
    data: () => ({
      step: 1,
      dados: {
        personalizacao: {link_logo: null},
        venda_rapida: {titulo: null},
        produtos: [],
        pedido_soluti: {},
        venda_item: {},
        agendamento: {},
        venda: {
          venda_id: null,
          status: 1,
          pagamento_obrigatorio: false
        },
        documentoAnexado: false
      },
      dialog: false,
      documentoCpf: false,
      documentoCnpj: false,
    }),
    computed: {
      itens() {
        let itens = [];
        itens.push({step: 1, title: "Compra"});
        itens.push({step: 2, title: "Pagamento"});
        itens.push({step: 3, title: "Documentos"});
        itens.push({step: 4, title: "Agenda"});
        return itens
      }
    },
    watch: {
      "$route.params.token": async function () {
        await this.loadPagina()
        await this.setPassoInicial()
      }
    },
    async created() {
      await this.loadPagina();
      await this.setPassoInicial()
    },
    methods: {
      async setPassoInicial() {
        console.log('setPassoInicial')
        if (this.dados.venda_rapida.venda_id) {
          this.step = 1
          const vendaPendente = this.dados.venda.venda_status_id === 1;
          const vendaPaga = this.dados.venda.venda_status_id === 2
          const temAgendamento = this.dados.agendamento.id !== undefined
          const protocolo = this.dados.pedido_soluti.protocolo

          if (!protocolo) {
            this.step = 1;
            return
          }

          // SE NAO FOI PAGO E É OBRIGATORIO PAGAR
          if (vendaPendente && !temAgendamento) {
            this.step = 2;
            return
          }

          // SE O PAGAMENTO NAO FOR OBRIGATORIO OU JÁ ESTIVER PAGO
          if (vendaPaga && !temAgendamento) {
            this.step = this.dados.documentoAnexado ? 4 : 3;
            return
          }
          //SE JA FOI PAGO E JÁ FOI AGENDADO
          if (this.dados.agendamento.data_hora_agendada && temAgendamento) {
            this.step = 5;
          }
        }
      },

      async loadPagina() {
        console.log('loadPagina')
        this.$store.commit("setStatus", true);
        await services.getPaginaVendaRapida(this.$route.params.token).then((resposta) => {
          this.dados = resposta.data;
          this.$store.commit("setStatus", false);
          this.formatarTextoDocumento(this.dados.produtos);
        }).catch(() => {
          this.$router.push("/web/link-expirado");
        });

        this.$emit("setBackgroundColor", this.dados.personalizacao.cor_pagina);
      },
      formatarTextoDocumento(produtos) {
        produtos.map(produto => {
          if (produto.tipo_certificado === 'CPF') {
            this.documentoCpf = true;
          } else if (produto.tipo_certificado === 'CNPJ') {
            this.documentoCnpj = true;
          }
        })
      }
    },
  }
  </script>

  <style scoped>

  #corpo-pagina {
    background: #ffffff;
    border-radius: 7px;
  }
  </style>
