import {http} from './config';

export const services = {

    // PAGAMENTO/COBRANCAS
    gerarChavePix: (form) => {
        var uri = '/cobranca/gerar/pix';
        return http.post(uri, form);
    },

    pagarCartao(form) {
        var uri = '/cobranca/cartao';
        return http.post(uri, form);
    },

    // AGENDA VIDEOCONFERENCIA
    listarHorarios(form) {
        var uri = '/videoconferencia/horarios-disponiveis';
        return http.post(uri, form);
    },

    agendarHorario(form) {
        var uri = '/videoconferencia/agendar';
        return http.post(uri, form);
    },

    //VENDA RAPIDA
    getPaginaVendaRapida(token) {
        var uri = '/venda-rapida/token/' + token;
        return http.get(uri);
    },

    createNovaVenda(form) {
        var uri = '/venda-rapida/nova-venda';
        return http.post(uri, form);
    },

    importarSoluti(form) {
        var uri = '/integracao/soluti/videoconferencia/importar';
        return http.post(uri, form);
    },

    novaVendaSoluti(form) {
        var uri = '/integracao/soluti/videoconferencia/nova-solicitacao';
        return http.post(uri, form);
    },


}
