<template>
    <div class="d-flex justify-center">
      <v-row>
        <v-col cols="12" md="12" lg="6" class="d-flex justify-center align-center">
          <div class="card-list">
            <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
              <div class="card-item__side -front">
                <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }"
                     v-bind:style="focusElementStyle"
                     ref="focusElement"></div>
                <div class="card-item__cover">
                  <ImagemAleatoria class="card-item__bg" alt=""></ImagemAleatoria>
                </div>

                <div class="card-item__wrapper">
                  <div class="card-item__top">
                    <img
                        src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                        class="card-item__chip" alt="">
                    <div class="card-item__type">
                      <transition name="slide-fade-up">
                        <img
                            v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'"
                            v-if="getCardType" v-bind:key="getCardType" alt="" class="card-item__typeImg">
                      </transition>
                    </div>
                  </div>
                  <label for="cardNumber" class="card-item__number" ref="cardNumber">
                    <template v-if="getCardType === 'amex'">
                   <span v-for="(n, index) in amexCardMask" :key="index">
                    <transition name="slide-fade-up">
                      <div class="card-item__numberItem"
                           :class="{ '-active' : n.trim() === '' }"
                           :key="index" v-if="cardNumber.length > index">
                        {{ cardNumber[index] }}
                      </div>
                      <div
                          class="card-item__numberItem"
                          :class="{ '-active' : n.trim() === '' }"
                          v-else
                          :key="index + 1"
                      >{{ n }}</div>
                    </transition>
                  </span>
                    </template>

                    <template v-else>
                    <span v-for="(n, index) in otherCardMask" :key="index">
                      <transition name="slide-fade-up">
                        <div class="card-item__numberItem"
                             :class="{ '-active' : n.trim() === '' }"
                             :key="index" v-if="cardNumber.length > index">
                          {{ cardNumber[index] }}
                        </div>
                        <div
                            class="card-item__numberItem"
                            :class="{ '-active' : n.trim() === '' }"
                            v-else
                            :key="index + 1"
                        >{{ n }}</div>
                      </transition>
                    </span>
                    </template>
                  </label>
                  <div class="card-item__content">
                    <label for="cardName" class="card-item__info" ref="cardName">
                      <span class="card-item__holder">Nome do Cartão</span>
                      <transition name="slide-fade-up">
                        <div class="card-item__name" v-if="cardName.length" key="1">
                          <transition-group name="slide-fade-up">
                            <div class="card-item__nameItem"
                                 v-for="(n, index) in cardName.replace(/\s\s+/g, ' ')"
                                 :key="index+1">
                              <span v-if="index === index">{{ n }}</span>
                            </div>
                          </transition-group>
                        </div>
                        <div class="card-item__name" v-else key="2">Nome Completo</div>
                      </transition>
                    </label>
                    <div class="card-item__date" ref="cardDate">
                      <label for="cardMonth" class="card-item__dateTitle">Validade</label>
                      <label for="cardMonth" class="card-item__dateItem">
                        <transition name="slide-fade-up">
                          <span v-if="cardMonth" v-bind:key="cardMonth">{{ cardMonth }}</span>
                          <span v-else key="2">Mês</span>
                        </transition>
                      </label>
                      /
                      <label for="cardYear" class="card-item__dateItem">
                        <transition name="slide-fade-up">
                          <span v-if="cardYear" v-bind:key="cardYear">{{ String(cardYear).slice(2, 4) }}</span>
                          <span v-else key="2">Ano</span>
                        </transition>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-item__side -back">
                <div class="card-item__cover">
                  <ImagemAleatoria class="card-item__bg" alt=""></ImagemAleatoria>
                </div>
                <div class="card-item__band"></div>
                <div class="card-item__cvv">
                  <div class="card-item__cvvTitle">CVV</div>
                  <div class="card-item__cvvBand">
                    <span v-for="(n, index) in cardCvv" :key="index">
                      *
                    </span>

                  </div>
                  <div class="card-item__type">
                    <img
                        v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'"
                        v-if="getCardType" class="card-item__typeImg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="12" lg="6">
          <v-row justify="center" class="pa-5">
            <v-col cols="12">
              <v-text-field
                  data-ref="cardNumber"
                  label="Numero do cartão"
                  outlined dense
                  autocomplete="off"
                  v-model="cardNumber"
                  v-mask="generateCardNumberMask"
                  v-on:focus="focusInput"
                  v-on:blur="blurInput"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                  label="Nome do cartão"
                  data-ref="cardName"
                  outlined dense
                  autocomplete="off"
                  v-model="cardName"
                  v-on:focus="focusInput"
                  v-on:blur="blurInput"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                  label="Mês"
                  data-ref="cardDate"
                  dense outlined
                  v-model="cardMonth"
                  :items="getOptionsMonths"
                  v-on:focus="focusInput"
                  v-on:blur="blurInput"
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-select
                  label="Ano"
                  data-ref="cardDate"
                  dense outlined
                  v-model="cardYear"
                  :items="getOptionsYears"
                  v-on:focus="focusInput"
                  v-on:blur="blurInput"
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-text-field
                  ref="cardCvv"
                  label="CVV"
                  outlined dense
                  autocomplete="off"
                  v-model="cardCvv"
                  v-mask="'####'"
                  v-on:focus="flipCard(true)"
                  v-on:blur="flipCard(false)"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn color="green" dark width="100%" @click="pagar"> Pagar</v-btn>
            </v-col>

          </v-row>
        </v-col>
      </v-row>

    </div>
  </template>


  <script>
  import {mask} from "vue-the-mask";
  import ImagemAleatoria from "@/components/ImagemAleatoria.vue";
  import {services} from "@/services/PaginasExternasServices";

  export default {
    name: "CartaoCredito",
    components: {ImagemAleatoria},
    directives: {mask},
    props: {
      venda_id: Number
    },
    data() {
      return {
        currentCardBackground: Math.floor(Math.random() * 25 + 1),
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        minCardYear: new Date().getFullYear(),
        amexCardMask: "#### ###### #####",
        otherCardMask: "#### #### #### ####",
        cardNumberTemp: "",
        isCardFlipped: false,
        focusElementStyle: null,
        isInputFocused: false,
        optionsMonth: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
      };
    },
    mounted() {
      this.cardNumberTemp = this.otherCardMask;
    },
    computed: {
      getCardType() {
        let number = this.cardNumber;
        let re = new RegExp("^4");
        if (number.match(re) != null) return "visa";

        re = new RegExp("^(34|37)");
        if (number.match(re) != null) return "amex";

        re = new RegExp("^5[1-5]");
        if (number.match(re) != null) return "mastercard";

        re = new RegExp("^6011");
        if (number.match(re) != null) return "discover";

        re = new RegExp('^9792')
        if (number.match(re) != null) return 'troy'

        return "visa"; // default type
      },
      generateCardNumberMask() {
        return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
      },
      minCardMonth() {
        if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
        return 1;
      },
      getOptionsMonths() {
        let months = [];
        for (let i = 1; i <= 12; i++) {
          months.push(String(i).padStart(2, '0'));
        }
        return months;
      },

      getOptionsYears() {
        let currentYear = new Date().getFullYear();
        let futureYears = 15;
        let years = [];

        for (let i = currentYear; i <= currentYear + futureYears; i++) {
          years.push(i.toString());
        }
        return years;
      }
    },
    watch: {
      cardYear() {
        if (this.cardMonth < this.minCardMonth) {
          this.cardMonth = "";
        }
      }
    },
    methods: {
      flipCard(status) {
        this.isCardFlipped = status;
      },
      focusInput(e) {
        this.isInputFocused = true;
        let targetRef = e.target.dataset.ref;
        let target = this.$refs[targetRef];
        this.focusElementStyle = {
          width: `${target.offsetWidth}px`,
          height: `${target.offsetHeight}px`,
          transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
        }
      },
      blurInput() {
        let vm = this;
        setTimeout(() => {
          if (!vm.isInputFocused) {
            vm.focusElementStyle = null;
          }
        }, 300);
        vm.isInputFocused = false;
      },
      pagar() {

        this.$store.commit("setStatus", true);
        var form = {
          venda_id: this.venda_id,
          nome: this.cardName,
          numero: this.cardNumber.replace(/\D/g, ''),
          data_validade: this.cardMonth + '/' + this.cardYear,
          cvv: this.cardCvv
        };

        services.pagarCartao(form).then(async () => {
          this.$notify("Seu pagamento está sendo processado...");
          this.$store.commit("setStatus", false);
        }).catch((err) => {
          this.$store.commit("setStatus", false);
          if (err.response.data.errors) {
            Object.entries(err.response.data.errors).forEach(([, msg]) => {
              this.$notify({type: "error", text: msg[0]});
            });
          }

          if (!err.response.data.status && err.response.data.msg) {
            this.$notify({type: "error", text: err.response.data.msg});
          }
        })
      }
    }
  }
  </script>


  <style lang="scss">
  @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

  .wrapper {
    min-height: 100vh;
    display: flex;
    padding: 50px 15px;
    @media screen and (max-width: 700px), (max-height: 500px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  .card-item {
    max-width: 430px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    width: 100%;

    @media screen and (max-width: 480px) {
      max-width: 310px;
      height: 220px;
      width: 90%;
    }

    @media screen and (max-width: 360px) {
      height: 180px;
    }

    &.-active {
      .card-item__side {
        &.-front {
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
        }

        &.-back {
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        }
      }
    }

    &__focus {
      position: absolute;
      z-index: 3;
      border-radius: 5px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border: 2px solid rgba(255, 255, 255, 0.65);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgb(8, 20, 47);
        height: 100%;
        border-radius: 5px;
        filter: blur(25px);
        opacity: 0.5;
      }

      &.-active {
        opacity: 1;
      }
    }

    &__side {
      border-radius: 15px;
      overflow: hidden;
      transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
      transform-style: preserve-3d;
      transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
      backface-visibility: hidden;
      height: 100%;

      &.-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
        z-index: 2;
        padding: 0;
        height: 100%;

        .card-item__cover {
          transform: rotateY(-180deg)
        }
      }
    }

    &__bg {
      max-width: 100%;
      display: block;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &__cover {
      height: 100%;
      background-color: #1c1d27;
      position: absolute;
      height: 100%;
      background-color: #1c1d27;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 15px;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 2, 29, 0.45);
      }
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding: 0 10px;

      @media screen and (max-width: 480px) {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 360px) {
        margin-bottom: 15px;
      }
    }

    &__chip {
      width: 60px;
      @media screen and (max-width: 480px) {
        width: 50px;
      }
      @media screen and (max-width: 360px) {
        width: 40px;
      }
    }

    &__type {
      height: 45px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      max-width: 100px;
      margin-left: auto;
      width: 100%;

      @media screen and (max-width: 480px) {
        height: 40px;
        max-width: 90px;
      }
      @media screen and (max-width: 360px) {
        height: 30px;
      }
    }

    &__typeImg {
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
      object-position: top right;
    }

    &__info {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 85px);
      padding: 10px 15px;
      font-weight: 500;
      display: block;

      cursor: pointer;

      @media screen and (max-width: 480px) {
        padding: 10px;
      }
    }

    &__holder {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 6px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__wrapper {
      font-family: "Source Code Pro", monospace;
      padding: 25px 15px;
      position: relative;
      z-index: 4;
      height: 100%;
      text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
      user-select: none;
      @media screen and (max-width: 480px) {
        padding: 20px 10px;
      }
    }

    &__name {
      font-size: 18px;
      line-height: 1;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    &__nameItem {
      display: inline-block;
      min-width: 8px;
      position: relative;
    }

    &__number {
      font-weight: 500;
      line-height: 1;
      color: #fff;
      font-size: 27px;
      margin-bottom: 35px;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 21px;
        margin-bottom: 15px;
        padding: 10px 10px;
      }

      @media screen and (max-width: 360px) {
        font-size: 19px;
        margin-bottom: 10px;
        padding: 10px 10px;
      }
    }

    &__numberItem {
      width: 16px;
      display: inline-block;

      &.-active {
        width: 30px;
      }

      @media screen and (max-width: 480px) {
        width: 13px;

        &.-active {
          width: 16px;
        }
      }

      @media screen and (max-width: 360px) {
        width: 12px;

        &.-active {
          width: 8px;
        }
      }
    }

    &__content {
      color: #fff;
      display: flex;
      align-items: flex-start;
    }

    &__date {
      flex-wrap: wrap;
      font-size: 18px;
      margin-left: auto;
      padding: 10px;
      display: inline-flex;
      width: 80px;
      white-space: nowrap;
      flex-shrink: 0;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    &__dateItem {
      position: relative;

      span {
        width: 22px;
        display: inline-block;
      }
    }

    &__dateTitle {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: -3px;
      width: 100%;

      @media screen and (max-width: 480px) {
        font-size: 12px;
        padding-bottom: 5px;
      }
    }

    &__band {
      background: rgba(0, 0, 19, 0.8);
      width: 100%;
      height: 50px;
      margin-top: 30px;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 360px) {
        height: 40px;
        margin-top: 10px;
      }
    }

    &__cvv {
      text-align: right;
      position: relative;
      z-index: 2;
      padding: 15px;

      .card-item__type {
        opacity: 0.7;
      }

      @media screen and (max-width: 360px) {
        padding: 10px 15px;
      }
    }

    &__cvvTitle {
      padding-right: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 5px;
    }

    &__cvvBand {
      height: 45px;
      background: #fff;
      margin-bottom: 30px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      color: #1a3b5d;
      font-size: 18px;
      border-radius: 4px;
      box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

      @media screen and (max-width: 480px) {
        height: 40px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 360px) {
        margin-bottom: 15px;
      }
    }
  }

  .card-item__date {
    width: 90px;
  }

  .slide-fade-up-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    position: relative;
  }

  .slide-fade-up-leave-active {
    transition: all 0.25s ease-in-out;
    position: absolute;
  }

  .slide-fade-up-enter {
    opacity: 0;
    transform: translateY(15px);
    pointer-events: none;
  }

  .slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(-15px);
    pointer-events: none;
  }
  </style>
