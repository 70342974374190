import Vue from 'vue'

Vue.filter('dinheiro', valor => {
    return `R$ ${parseFloat(valor).toFixed(2)}`.replace('.', ',')
});

Vue.filter('porcentagem', valor => {
    return `${parseFloat(valor).toFixed(2)}%`.replace('.', ',')
});

Vue.filter('money', valor => {
    if (valor === 'undefined' || valor == null) {
        valor = 0;
    }
    return `${parseFloat(valor).toFixed(2)}`.replace('.', ',')
});

Vue.filter('onlyNumber', valor => {
    return valor.replace(/[\D]/g, "")
});

Vue.filter('formataData', date => {
    if (!date || date.length < 8) {
        return date;
    }
    let [year, month, day] = date.split("-");
    if (day.length > 2) {
        day = day.substr(0, 2)
    }
    return `${day}/${month}/${year}`;
});

Vue.filter('formataDataHora', date => {
    if (date == null || date.length < 8) {
        return date;
    }
    var [year, month, day] = date.split("-");
    var [iday, time] = day.split(" ")
    var [hour, min] = time.split(":")
    return `${iday}/${month}/${year} ${hour}:${min}`;
});

Vue.filter('formataTitulo', str => {
    if (typeof str != "string") {
        return;
    }
    str = str.replace('id', '');
    str = str.replace(/[^a-zA-Z0-9]/g, ' ');
    str = str.replace(/(^\w{1})|(\s+\w{1})/g, (letra) => letra.toUpperCase());
    return str;
});

Vue.filter('duasPrimeirasLetras', string => {
    const myArray = String(string).split(" ");
    let letters = '';
    myArray.forEach((el, key) => {
        if (key > 1) {
            return false;
        }
        letters += el.charAt(0).toUpperCase()
    })
    return letters
});

Vue.filter('formataCnpj', cnpj => {
    if ((typeof cnpj) != 'string') return cnpj
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
});

Vue.filter('formataCpf', cpf => {
    if ((typeof cpf) != 'string') return cpf
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
});


Vue.filter('removeAcentos', text => {
    if ((typeof text) != 'string') return text
    return text.normalize("NFD").replace(/[^a-zA-Z0-9-\s]/g, "");
});
Vue.filter('primeiraMaiuscula', text => {
    if ((typeof text) != 'string') return text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
});
export default Vue;
